.about-section-container {
    display: flex;
    width: 100%;
    min-height: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 75px;
    box-sizing: border-box;
}

.book-yourself-callout-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    margin-top: 15vh;
}

.book-yourself-callout-container p, a {
    min-width: max-content;
}

.book-yourself-callout-background-graphic {
    transition: 0.3s cubic-bezier(0.23, 1, 0.320, 1);
}

.book-yourself-callout-background-graphic:hover {
    transform: scale(1.02) translateY(-5px);
}