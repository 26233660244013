.parallax-outer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateZ(0);
  z-index: -10;
}

.parallax-inner {
 height: 100%;
 width: 100%;
}
