.photo-filter * {
  margin: 0px !important;
}


.gradient-filter {
  z-index: 1 !important;
}

.flat-filter {
  z-index: 1 !important;
}

/* IE 10, 11 & MS Edge fallback for filters */
/* @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {

} */

.mix-blend-mode-multiply {
  opacity: 0.4;
}

.mix-blend-mode-color {
  opacity: 0.4;
}

.filter-background {
  z-index: -10;
}

@supports (mix-blend-mode: multiply) and (mix-blend-mode: color) {

  .mix-blend-mode-multiply {
    mix-blend-mode: multiply;
    opacity: 1;
  }

  .mix-blend-mode-color {
    mix-blend-mode: color;
    opacity: 1;
  }

  .filter-background {
    filter: none;
  }

}
