.podcast-episode-card {
    display: flex;
    min-width: 200px;
    width: 200px;
    height: 200px;
    background-position: center center;
    background-size: cover;
    margin: 10px;
    border-radius: 10px;
    cursor: pointer;

    /* Interactions */
    transition: 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.podcast-episode-card-inner-content {
    /* Interactions */
    opacity: 0;
    transition: 0.5s cubic-bezier(0.23, 1, 0.320, 1);
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
}

/* .podcast-episode-card-inner-content p {
    margin-top: auto;
    margin-bottom: auto;
} */

.podcast-episode-and-player-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.podcast-episode-card:hover .podcast-episode-card-inner-content {
    opacity: 1;
    transition: 0.5s cubic-bezier(0.23, 1, 0.320, 1);
}

.podcast-episode-card:hover {
    transform: scale(1.01);
}

.podcast-episode-card:hover * {
    opacity: 1;
}

.podcast-episode-card:active {
    transform: scale(0.98);
}

.podcast-episode-grid {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 750px;
    margin: auto;
}

/* Selected Episode Styling */
.selected-episode-container {
    display: flex;
    margin-top: 25vh;
    margin-bottom: 20vh;
    position: relative;
}

.selected-episode-text-container {
    height: 200px;
    overflow-y: scroll;
    padding: 15px;
    box-sizing: border-box;
}

.selected-episode-loading-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 200px;
    text-align: center;
}

/* Responsiveness */
@media only screen and (max-width: 750px) {
    .selected-episode-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 0px 5px 50px rgba(0,0,0,0.1);
        margin: 15px 15px 20vh 15px !important;
        border-radius: 15px;
        padding: 15px;
        box-sizing: border-box;
    }

    .selected-episode-text-container {
        /* padding: 75px; */
        /* margin-top: 50px; */
        margin: 50px 50px 0px 50px;
        height: unset;
    }

    .selected-background-graphic {
        display: none !important;
    }
}