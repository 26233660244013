footer.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    text-align: center;
    /* position: absolute; */
    width: 100%;
    height: 70px;
    z-index: 1;
    bottom: 0;
    /* background-color: teal; */
    font-family: 'Comfortaa', cursive;
    margin-top: auto;
    padding: 50px 10px 10px 10px;
}

footer a {
    color: var(--accent-color);
}