.App, #root, html, body {
  height: 100%;
  width: 100%;
  font-family: "Elianto", sans-serif;
  position: relative;
}

/* Colours */
html {
  --accent-color: #3acbff;
  --headline-text-colour: white;
  --subheadline-text-colour: #DCDCDC;

  --primary-text-colour: #232220;
  --secondary-text-colour: #757575;
}

h1, h2, h3, h4 {
  margin: 0;
}

h1 {
  color: var(--headline-text-colour);
  font-weight: 600;
}

h2 {
  font-weight: 500;
}

h2.light {
  color: var(--subheadline-text-colour);
}

p {
  color: var(--secondary-text-colour);
}

p, a {
  font-size: 12pt;
  font-weight: 400;
  margin: 0;
}

a {
  text-decoration: none;
  color: #2387ac;
  font-weight: 500;
  opacity: 0.75;
  transition: 0.3s cubic-bezier(0.86, 0, 0.07, 1);
}

a:hover {
  opacity: 1;
}

mark {
  color: var(--accent-color);
  background: none;
}

/* Resets */

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

/* Global Styling. */
.section {
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;
}

.text-section-container {
  max-width: 750px;
  text-align: center;
}

.text-section-container * {
  margin: 5px;
}