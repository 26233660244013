.navigation-container {
    display: flex;
    z-index: 1;
}

.navigation-links-container {
    margin-left: auto;
}

.navigation-links-container > a {
    margin: 0px 10px 0px 10px;
}

.navigation-links-container:last-child {
    margin-right: 0;
}

/*  Home Section */
.homesection-container {
    min-height: 100%;
    padding: 50px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Views */
.listen-on-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* margin-top: 20%; */
}

.listen-on-container * {
    margin-left: 10px;
    margin-right: 10px;
    width: 30px;
}

.listen-on-container:first-child {
    margin-left: 0;
}

.listen-on-container:last-child {
    margin-right: 0;
}

.homesection-download-link-container {
    margin-top: 50px;
    text-align: center;
}