.hero-container, .hero-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero-container {
    height: 100%;
    width: 100%;
}

.hero-container h1, h2 {
    text-align: center;
}

.hero-text-container h1 {
    font-size: 45pt;
    font-weight: 500;
    font-family: 'Permanent Marker', cursive;
}