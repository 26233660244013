.navigation-container {
    padding: 50px;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
}

.navigation-container a {
    color: white;
    font-family: 'Permanent Marker', cursive;
}